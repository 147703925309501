<template>
  <div class="container">
    <div>
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            ติดต่อเรา
          </div>
        </div>
      </div>
    </div>

    <div class="cardCustom mb-8">
      <div class="p-8">
        <div class="content">
          สำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งชาติ
        </div>

        <div class="content">120 หมู่ 3 ชั้น 9 อาคารรัฐประศาสนภักดี</div>

        <div class="content">
          ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550
        </div>

        <div class="content">
          ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210
        </div>

        <div class="content">
          โทร. 0 2142 1202 | แฟกซ์. 0 2143 7962 | อีเมล์. pr.onde@onde.go.th
        </div>
      </div>

      <div class="mapTitle pr-8 pl-8">แผนที่</div>

      <div class="p-8">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.27745284847!2d100.56295211483196!3d13.88235129026309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e283b6c7c36f77%3A0xf6d26829f7bde49a!2z4Liq4Liz4LiZ4Lix4LiB4LiH4Liy4LiZ4LiE4LiT4Liw4LiB4Lij4Lij4Lih4LiB4Liy4Lij4LiU4Li04LiI4Li04LiX4Lix4Lil4LmA4Lie4Li34LmI4Lit4LmA4Lio4Lij4Lip4LiQ4LiB4Li04LiI4LmB4Lil4Liw4Liq4Lix4LiH4LiE4Lih4LmB4Lir4LmI4LiH4LiK4Liy4LiV4Li0!5e0!3m2!1sen!2sth!4v1622912375252!5m2!1sen!2sth"
          width="100%"
          height="315px"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>

      <div class="title mt-2 mb-6" align='center'>
        ติดต่อสอบถามเพิ่มเติม
      </div>

      <div class="pl-8 pr-8">
        <div class="grid grid-cols-2 gap-x-4">
          <div class="mb-4">
            <label class="mb-2">ชื่อ <font color="#FA4238">*</font></label>
            <div class="mb-2"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="ชื่อ"
              v-model="firstName"
            />
          </div>

          <div class="mb-4">
            <label class="mb-2">นามสกุล <font color="#FA4238">*</font></label>
            <div class="mb-2"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="นามสกุล"
              v-model="lastName"
            />
          </div>

          <div class="mb-4">
            <label class="mb-2">อีเมล <font color="#FA4238">*</font></label>
            <div class="mb-2"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="email"
              placeholder="อีเมล"
              v-model="email"
            />
          </div>

          <div class="mb-4">
            <label class="mb-2"
              >ชื่อบริษัท <font color="#FA4238">*</font></label
            >
            <div class="mb-2"></div>
            <input
              class="border-2 border-red-500 w-full mb-4"
              type="text"
              placeholder="ชื่อบริษัท"
              v-model="companyName"
            />
          </div>
        </div>

        <div class="mb-4">
          <label class="mb-2"
            >เรื่องที่ต้องการติดต่อ <font color="#FA4238">*</font></label
          >
          <div class="mb-2"></div>
          <textarea
            placeholder="เรื่องที่ต้องการติดต่อ"
            class="mb-4"
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="subject"
          ></textarea>
        </div>

        <div class="pb-12" align="center">
          <button @click="contact()">ส่งแบบฟอร์ม</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useAuth from "../hooks/useAuth";
export default {
  methods: {
    contact() {
      useAuth
        .contact(
          this.firstName,
          this.lastName,
          this.email,
          this.companyName,
          this.subject
        )
        .then((response) => {
          if (response.data.status === true) {
            this.$swal({
              title: "ส่งเมลสำเร็จ",
              icon: 'success',
              // html: `<div align='center'><img src='${process.env.VUE_APP_URL_API}/icon/success.svg' width='100px;' height='100px;' /></div>`,
              confirmButtonText: "ยืนยัน",
            });
          }
        });
    },
  },
  setup() {
    const firstName = ref(null);
    const lastName = ref(null);
    const email = ref(null);
    const companyName = ref(null);
    const subject = ref(null);
    return { firstName, lastName, email, companyName, subject };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  color: #242424;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.mapTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  color: #fa4238;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

textarea {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 200px;
  padding-left: 15px;
  padding-top: 5px;
}

button {
  width: 218px;
  height: 51px;
  background: #573c81;
  border-radius: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
}
</style>